import React, { memo } from 'react';

import { TweetLocation } from '~/app/Constants';

import CanariasSvg from '../img/ic_canarias.svg';
import ElHierroSvg from '../img/ic_el_hierro.svg';
import FuerteventuraSvg from '../img/ic_fuerteventura.svg';
import GranCanariaSvg from '../img/ic_gran_canaria.svg';
import LaGomeraSvg from '../img/ic_la_gomera.svg';
import LaPalmaSvg from '../img/ic_la_palma.svg';
import LanzaroteSvg from '../img/ic_lanzarote.svg';
import TenerifeSvg from '../img/ic_tenerife.svg';

interface LocationIconProps {
  location?: TweetLocation;
}

/**
 * Icon for every island, defaults to Canarias when no location is provided
 */
export const LocationIcon = memo(function LocationIcon({
  location,
}: LocationIconProps): JSX.Element {
  if (!location) {
    return <CanariasSvg />;
  }
  switch (location) {
    case 'canarias':
      return <CanariasSvg />;
    case 'elhierro':
      return <ElHierroSvg />;
    case 'fuerteventura':
      return <FuerteventuraSvg />;
    case 'grancanaria':
      return <GranCanariaSvg />;
    case 'lagomera':
      return <LaGomeraSvg />;
    case 'lanzarote':
      return <LanzaroteSvg />;
    case 'lapalma':
      return <LaPalmaSvg />;
    case 'tenerife':
      return <TenerifeSvg />;
  }
});
