import './TweetListItem.scss';

import EnvelopeIcon from 'font-awesome-svg-png/black/svg/envelope.svg';
import LinkIcon from 'font-awesome-svg-png/black/svg/link.svg';
import PhoneIcon from 'font-awesome-svg-png/black/svg/phone.svg';
import ShareIcon from 'font-awesome-svg-png/black/svg/share-alt.svg';
import React, { PureComponent } from 'react';

import { LocationIcon } from '~/app/LocationIcon';
import { Tweet } from '~/app/tweetTypes';

import Button from '../components/Button';
import { LOCATION_TO_TRANSLATION_MAP } from './Constants';

interface TweetHandler {
  (tweet: Tweet): void;
}

interface TweetListItemProps {
  onLinkClick: TweetHandler;
  onShare: TweetHandler;
  onPhoneClick: TweetHandler;
  onEmailClick: TweetHandler;
  tweet: Tweet;
}

class TweetListItem extends PureComponent<TweetListItemProps> {
  handleEmailAction = (): void => {
    const { onEmailClick, tweet } = this.props;
    onEmailClick(tweet);
  };

  handleLinkClick = (): void => {
    const { onLinkClick, tweet } = this.props;
    onLinkClick(tweet);
  };

  handlePhoneAction = (): void => {
    const { onPhoneClick, tweet } = this.props;
    onPhoneClick(tweet);
  };

  handleShareAction = (): void => {
    const { onShare, tweet } = this.props;
    onShare(tweet);
  };

  render(): JSX.Element {
    const { date, email, location, longText, phoneNumber, urlList } = this.props.tweet;
    const tweetTitle = `Empleo en ${location ? LOCATION_TO_TRANSLATION_MAP[location] : 'Canarias'}`;
    return (
      <div className="tweetListItem">
        <div className="tweetListItem-header">
          <div className="tweetListItem-headerImageWrapper">
            <LocationIcon location={location} />
          </div>
          <div className="tweetListItem-headerTextWrapper">
            <div className="tweetListItem-title">{tweetTitle}</div>
            <div className="tweetListItem-headerSub">
              <span className="tweetListItem-date">{new Date(date).toLocaleDateString()}</span>
            </div>
          </div>
        </div>
        <div className="tweetListItem-body">{longText.trim()}</div>
        <div className="tweetListItem-footer">
          {email && (
            <Button
              className="tweetListIem-buttonWithTextAndIcon"
              onClick={this.handleEmailAction}
              title="Enviar CV"
            >
              <EnvelopeIcon className="tweetListItem-icon" />
              Enviar CV
            </Button>
          )}
          {urlList.length > 0 && (
            <Button
              className="tweetListIem-buttonWithTextAndIcon"
              onClick={this.handleLinkClick}
              title="Enlace"
            >
              <LinkIcon className="tweetListItem-icon tweetListItem-icon--link" />
              Enlace
            </Button>
          )}
          {phoneNumber && (
            <Button
              className="tweetListIem-buttonWithTextAndIcon"
              onClick={this.handlePhoneAction}
              title="Llamar"
            >
              <PhoneIcon className="tweetListItem-icon" />
              Llamar
            </Button>
          )}
          <Button onClick={this.handleShareAction} title="Compartir">
            <ShareIcon className="tweetListItem-icon" />
          </Button>
        </div>
      </div>
    );
  }
}

export default TweetListItem;
