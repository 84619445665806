import './TweetMasonryLoader.scss';

import window from 'global/window';
import throttle from 'lodash/throttle';
import React, { Component } from 'react';

import { times } from './ArrayHelpers';
import { getColumnCount } from './LayoutHelpers';

const SSR_ROW_COUNT_DEFAULT = 6;

interface TweetMasonryLoaderProps {
  cellHeight: number;
  cellSpacing: number;
}

interface TweetMasonryLoaderState {
  columnCount: number;
  rowCount: number;
}

export default class TweetMasonryLoader extends Component<TweetMasonryLoaderProps> {
  state: TweetMasonryLoaderState = this.getPlaceholderDimensions();

  getPlaceholderDimensions(): TweetMasonryLoaderState {
    const columnCount = getColumnCount();
    const rowCount = Math.ceil(window.innerHeight / this.props.cellHeight) || SSR_ROW_COUNT_DEFAULT;
    return {
      columnCount,
      rowCount,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = throttle(() => {
    this.setState(this.getPlaceholderDimensions());
  }, 100);

  render() {
    const { cellHeight, cellSpacing } = this.props;
    const { columnCount, rowCount } = this.state;
    return (
      <div className="tweetMasonryLoader">
        <div className="tweetMasonryLoader-grid">
          {times(columnCount * rowCount, i => (
            <div
              key={i}
              className="tweetMasonryLoader-itemWrapper"
              style={{
                height: cellHeight,
                paddingLeft: cellSpacing / 2,
                paddingRight: cellSpacing / 2,
                paddingBottom: cellSpacing,
                width: `${100 / columnCount}%`,
              }}
            >
              <div className="tweetMasonryLoader-item">
                <div className="tweetMasonryLoader-itemPlaceholderHeader" />
                <div className="tweetMasonryLoader-itemPlaceholderBody" />
                <div className="tweetMasonryLoader-itemPlaceholderFooter" />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
