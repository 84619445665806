import React from 'react';
import { Helmet } from 'react-helmet';

import { TweetProvider } from '~/app/TweetProvider';
import TweetView from '~/app/TweetView';
import Layout from '~/components/Layout';

const TenempleoApp = (): JSX.Element => {
  return (
    <TweetProvider>
      <Layout>
        <Helmet>
          <meta
            content="En nuestra página Tenempleo tendrás a diario el mayor listado actualizado de ofertas de trabajo de Canarias directamente en tu móvil u ordenador."
            name="description"
          />
        </Helmet>
        <TweetView />
      </Layout>
    </TweetProvider>
  );
};

export default TenempleoApp;
