import window from 'global/window';

export const DEFAULT_HEIGHT = 300;
export const BASE_PADDING = 20;
const MAX_CONTAINER_WIDTH = 1200;
const SSR_DEVICE_WIDTH_DEFAULT = 400;

export enum DEVICE_TYPES {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
  WIDE = 'wide',
}
const TYPE_WIDTH_INTERVALS: Record<DEVICE_TYPES, [number, number]> = {
  [DEVICE_TYPES.MOBILE]: [0, 767],
  [DEVICE_TYPES.TABLET]: [768, 999],
  [DEVICE_TYPES.DESKTOP]: [1000, 1399],
  [DEVICE_TYPES.WIDE]: [1400, Infinity],
};
const TYPE_COLUMN_COUNT: Record<DEVICE_TYPES, number> = {
  [DEVICE_TYPES.MOBILE]: 1,
  [DEVICE_TYPES.TABLET]: 2,
  [DEVICE_TYPES.DESKTOP]: 3,
  [DEVICE_TYPES.WIDE]: 3,
};
export const getDeviceTypeFromWidth = (width: number): DEVICE_TYPES => {
  const entry = Object.entries(TYPE_WIDTH_INTERVALS).find(
    ([, [intervalStart, intervalEnd]]) => width >= intervalStart && width <= intervalEnd
  );
  if (!entry) {
    throw new Error('Non covered with');
  }
  return entry[0] as DEVICE_TYPES;
};
export const getDeviceTypeFromWindowWidth = (): DEVICE_TYPES =>
  getDeviceTypeFromWidth(window.innerWidth || SSR_DEVICE_WIDTH_DEFAULT);
export const getColumnCount = (): number => TYPE_COLUMN_COUNT[getDeviceTypeFromWindowWidth()];

export const getAvailableWidth = (): number =>
  Math.min(window.innerWidth || SSR_DEVICE_WIDTH_DEFAULT, MAX_CONTAINER_WIDTH) -
  2 * BASE_PADDING -
  (getColumnCount() - 1) * BASE_PADDING;
