import React, { useContext } from 'react';

import { TweetContext } from '~/app/TweetContext';

import TweetList from './TweetList';

const TweetView = () => {
  const { isLoading, error, filter, tweets } = useContext(TweetContext);
  return (
    <div className="container">
      {error && <div>Ha ocurrido un error, por favor, actualice la página.</div>}
      <TweetList filter={filter} isLoading={isLoading} tweets={tweets} />
    </div>
  );
};

export default TweetView;
