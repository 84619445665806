/**
 * Helper to run a mapping function on an "n" length array
 * @param {Number} n
 * @param {Function} fn
 * @returns {Array} result of calling fn(i) for each [0..n-1]
 */
export const times = (n: number, fn: (a: number) => any): any[] => {
  const mappedValues = [];
  for (let i = 0; i < n; i++) {
    mappedValues.push(fn(i));
  }
  return mappedValues;
};
