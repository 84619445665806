import 'isomorphic-fetch';

const TWITTER_PROXY_URL =
  'https://europe-west1-hardy-rhythm-220516.cloudfunctions.net/tenempleo-twitter-proxy';

const fetchTweets = (): Promise<any> => {
  return fetch(TWITTER_PROXY_URL)
    .then(response => response.json())
    .catch(err => {
      // TODO handle error
      console.error(err);
    });
};

export default fetchTweets;
