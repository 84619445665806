import React, {
  ComponentType,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { TweetLocation } from '~/app/Constants';
import fetchTweets from '~/app/FetchTweetsService';
import { TweetContext, TweetState } from '~/app/TweetContext';
import { Tweet } from '~/app/tweetTypes';

type TweetProviderProps = PropsWithChildren<{}>;

/**
 * Provider that fetches tweets and provides them to consumers
 */
export const TweetProvider: ComponentType<TweetProviderProps> = memo(function TweetProvider({
  children,
}: TweetProviderProps): JSX.Element {
  const [state, setState] = useState<Omit<TweetState, 'setFilter'>>({
    isLoading: false,
  });
  const [allTweets, setAllTweets] = useState<Tweet[] | undefined>();

  const setFilter = useCallback(
    (filter: TweetLocation): void => {
      setState({
        ...state,
        filter,
        isLoading: true,
      });
      setTimeout(
        () =>
          setState({
            isLoading: false,
            filter,
            tweets: allTweets?.filter(tweet => tweet.location === filter),
          }),
        200
      );
    },
    [allTweets, state]
  );

  useEffect(() => {
    setState({
      isLoading: true,
    });
    fetchTweets()
      .then(tweets => {
        setState({
          isLoading: false,
          tweets,
        });
        setAllTweets(tweets);
      })
      .catch(err => {
        setState({
          isLoading: false,
          error: err,
        });
      });
  }, []);

  const value = useMemo<TweetState>(
    () => ({
      ...state,
      setFilter,
    }),
    [state, setFilter]
  );

  return <TweetContext.Provider value={value}>{children}</TweetContext.Provider>;
});
