import './TweetList.scss';

import window from 'global/window';
import debounce from 'lodash/debounce';
import React, { Component, Fragment, RefObject } from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  CellRenderer,
  createMasonryCellPositioner,
  Masonry,
  Positioner,
  WindowScroller,
} from 'react-virtualized';

import { LOCATION_TO_TRANSLATION_MAP, TweetLocation } from '~/app/Constants';
import { Tweet } from '~/app/tweetTypes';

import Dialog from '../components/Dialog';
import ShareButtons from '../components/ShareButtons';
import { ACTIONS, CATEGORIES, trackEvent } from './Analytics';
import { BASE_PADDING, DEFAULT_HEIGHT, getAvailableWidth, getColumnCount } from './LayoutHelpers';
import TweetListItem from './TweetListItem';
import TweetMasonryLoader from './TweetMasonryLoader';

interface TweetListProps {
  isLoading: boolean;
  filter?: TweetLocation;
  tweets?: Tweet[];
}

interface TweetListState {
  columnWidth: number;
  tweetToShare?: Tweet;
}

export default class TweetList extends Component<TweetListProps, TweetListState> {
  cache: CellMeasurerCache;

  cellPositioner: Positioner;

  lastWidth = window.innerWidth;

  masonryRef: RefObject<Masonry> = React.createRef();

  state: TweetListState = {
    columnWidth: getAvailableWidth() / getColumnCount(),
    tweetToShare: undefined,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);

    if (this.props.tweets) {
      this.updateCachePositionerAndColumnWidth();
    }
  }

  componentDidUpdate(prevProps: TweetListProps) {
    if (this.props.tweets && this.props.tweets !== prevProps.tweets) {
      this.updateCachePositionerAndColumnWidth();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  updateCachePositionerAndColumnWidth = () => {
    this.cache = new CellMeasurerCache({
      defaultHeight: DEFAULT_HEIGHT,
      defaultWidth: getAvailableWidth() / getColumnCount(),
      fixedWidth: true,
    });

    this.cellPositioner = createMasonryCellPositioner({
      cellMeasurerCache: this.cache,
      columnCount: getColumnCount(),
      columnWidth: getAvailableWidth() / getColumnCount(),
      spacer: BASE_PADDING,
    });

    const columnWidth = getAvailableWidth() / getColumnCount();
    this.setState({ columnWidth });
  };

  debouncedSizeUpdate = debounce(() => {
    this.updateCachePositionerAndColumnWidth();
  }, 500);

  handleDialogDismiss = () => {
    this.setState({ tweetToShare: undefined });
  };

  handleResize = () => {
    if (this.lastWidth === window.innerWidth) {
      return;
    }
    this.lastWidth = window.innerWidth;
    this.setState({ columnWidth: 0 });
    this.debouncedSizeUpdate();
  };

  handleEmailClick = (tweet: Tweet) => {
    window.open(`mailto:${tweet.email}`);
    trackEvent({
      action: ACTIONS.EMAIL,
      category: CATEGORIES.OFFER,
    });
  };

  handleLinkClick = (tweet: Tweet) => {
    window.open(tweet.urlList[0]);
    trackEvent({
      action: ACTIONS.LINK,
      category: CATEGORIES.OFFER,
    });
  };

  handlePhoneClick = (tweet: Tweet) => {
    window.open(`tel:${tweet.phoneNumber}`);
    trackEvent({
      action: ACTIONS.PHONE,
      category: CATEGORIES.OFFER,
    });
  };

  handleShare = (tweet: Tweet) => {
    this.setState({ tweetToShare: tweet });
    trackEvent({
      action: ACTIONS.SHARE,
      category: CATEGORIES.OFFER,
    });
  };

  cellRenderer: CellRenderer = ({ index, key, parent, style }) => {
    const tweet = this.props.tweets![index];
    return (
      <CellMeasurer cache={this.cache} index={index} key={key} parent={parent}>
        <div style={style}>
          <TweetListItem
            onEmailClick={this.handleEmailClick}
            onLinkClick={this.handleLinkClick}
            onPhoneClick={this.handlePhoneClick}
            onShare={this.handleShare}
            tweet={tweet}
          />
        </div>
      </CellMeasurer>
    );
  };

  maybeRenderDialog() {
    const { tweetToShare } = this.state;
    if (!tweetToShare) {
      return null;
    }

    const url = tweetToShare.urlList[0];
    const extraText = url ? undefined : tweetToShare.phoneNumber || tweetToShare.email;
    return (
      <Dialog onDismiss={this.handleDialogDismiss}>
        <h4 className="tweetList-dialogTitle">Compartir oferta</h4>
        <ShareButtons extraText={extraText} title={tweetToShare.shortText} url={url} />
      </Dialog>
    );
  }

  render() {
    const { columnWidth } = this.state;
    const { filter, isLoading, tweets } = this.props;

    if (!isLoading && filter && tweets && !tweets.length) {
      return <div>No se han encontrado resultados para {LOCATION_TO_TRANSLATION_MAP[filter]}.</div>;
    }

    return isLoading || !columnWidth || !this.cache ? (
      <TweetMasonryLoader cellHeight={DEFAULT_HEIGHT} cellSpacing={BASE_PADDING} />
    ) : (
      <Fragment>
        <WindowScroller>
          {({ height, scrollTop }) => (
            <AutoSizer disableHeight height={height} scrollTop={scrollTop}>
              {({ width }) => (
                <Masonry
                  ref={this.masonryRef}
                  autoHeight
                  cellCount={tweets?.length || 0}
                  cellMeasurerCache={this.cache}
                  cellPositioner={this.cellPositioner}
                  cellRenderer={this.cellRenderer}
                  ensureReRender={tweets}
                  height={height}
                  scrollTop={scrollTop}
                  width={width}
                />
              )}
            </AutoSizer>
          )}
        </WindowScroller>
        {this.maybeRenderDialog()}
      </Fragment>
    );
  }
}
